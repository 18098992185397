export default {
  get: {
    funKey: 'get',
    name: '全部',
    apis: [{
      path: '/internalcontrol/assets',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_check_record',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_check_record',
      method: 'POST'
    }]
  }
}