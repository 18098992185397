export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/org/tree',
      method: 'GET'
    }]
  },
  tree: {
    funKey: 'tree',
    name: '树形展示',
    apis: [{
      path: '/internalcontrol/org/tree',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/internalcontrol/org',
      method: 'POST'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/internalcontrol/org/{id}',
      method: 'PUT'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/internalcontrol/org/{id}',
      method: 'DELETE'
    }]
  },
  goWorker: {
    funKey: 'goWorker',
    name: '跳转人员管理'
  },
  goCount: {
    funKey: 'goCount',
    name: '跳转统计页面'
  }
}