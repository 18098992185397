export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/purchase',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/internalcontrol/purchase',
      method: 'POST'
    },
    {
      path: '/internalcontrol/worker',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_method_batch/detail',
      method: 'GET'
    }]
  },
  edit: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/internalcontrol/purchase/{id}',
      method: 'PUT'
    },
    {
      path: '/internalcontrol/worker',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_method_batch/detail',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/internalcontrol/purchase/{id}',
      method: 'DELETE'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '扫描件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取扫描件'
  },
  updateDataFile: {
    funKey: 'updateDataFile',
    name: '上传扫描件'
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载扫描件'
  },
  delDataFile: {
    funKey: 'delDataFile',
    name: '删除扫描件'
  }
}