export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/assets_in',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/internalcontrol/assets_in',
      method: 'POST'
    },
    {
      path: '/internalcontrol/assets',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    }]
  },
  edit: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/internalcontrol/assets_in/{id}',
      method: 'PUT'
    },
    {
      path: '/internalcontrol/assets',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    }]
  },
  over: {
    funKey: 'over',
    name: '出库',
    apis: [{
      path: '/internalcontrol/assets_in/{id}',
      method: 'PUT'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/internalcontrol/assets_in/{id}',
      method: 'DELETE'
    }]
  }
}