export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/acceptance',
      method: 'GET'
    }]
  },
  new: {
    funKey: 'new',
    name: '新增',
    apis: [{
      path: '/internalcontrol/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase',
      method: 'GET'
    },
    {
      path: '/internalcontrol/acceptance',
      method: 'POST'
    }]
  },
  editData: {
    funKey: 'editData',
    name: '修改',
    apis: [{
      path: '/internalcontrol/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase',
      method: 'GET'
    },
    {
      path: '/internalcontrol/acceptance/{id}',
      method: 'PUT'
    }]
  },
  start: {
    funKey: 'start',
    name: '开始验收',
    apis: [{
      path: '/internalcontrol/acceptance/{id}',
      method: 'PUT'
    }]
  },
  end: {
    funKey: 'end',
    name: '结束',
    apis: [{
      path: '/internalcontrol/acceptance/{id}',
      method: 'PUT'
    }]
  },
  reStart: {
    funKey: 'reStart',
    name: '重新验收',
    apis: [{
      path: '/internalcontrol/acceptance/{id}',
      method: 'PUT'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/internalcontrol/acceptance/{id}',
      method: 'DELETE'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '明细'
  },
  data_file: {
    funKey: 'data_file',
    name: '扫描件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取扫描件'
  },
  updateDataFile: {
    funKey: 'updateDataFile',
    name: '上传扫描件',
    apis: [{
      path: '/internalcontrol/acceptance/{id}',
      method: 'PUT'
    }]
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载扫描件'
  },
  delDataFile: {
    funKey: 'delDataFile',
    name: '删除扫描件',
    apis: [{
      path: '/internalcontrol/acceptance/{id}',
      method: 'PUT'
    }]
  }
}