import worker from './worker'
import org from './org'
import orgType from './orgType'
import fun from './fun'
import menu from './menu'
import role from './role'
import user from './user'
import assetsManage from './assetsManage'
import assetsCheck from './assetsCheck'
import assetsIn from './assetsIn'
import assetsRepair from './assetsRepair'
import assetsRepairApply from './assetsRepairApply'
import assetsRepairApplyRecord from './assetsRepairApplyRecord'
import assetsScrapApply from './assetsScrapApply'
import assetsScrapApplyRecord from './assetsScrapApplyRecord'

import assetsType from './assetsType'
import goods from './goods'
import budget from './budget'
import purchase from './purchase'
import purchaseContract from './purchaseContract'
import purchaseImplement from './purchaseImplement'
import purchaseDetail from './purchaseDetail'
import purchaseSp from './purchaseSp'
import operationLog from './operationLog'
import orgInfo from './org_info'
import home from './home'

import purchaseBatch from './purchaseBatch'
import purchaseMethodBatch from './purchaseMethodBatch'
import bill from './bill'
import accept from './accept'
import acceptMy from './acceptMy'

export default [{
  routerNames: ['home'],
  funs: home
},
{
  routerNames: ['accept.manage'],
  funs: accept
},
{
  routerNames: ['accept.my'],
  funs: acceptMy
},
{
  routerNames: ['purchase_method.index', 'purchase_method.handle'],
  funs: purchaseMethodBatch
},
{
  routerNames: ['purchase.index', 'purchase.handle'],
  funs: purchaseBatch
},
{
  routerNames: ['budget.manage', 'budget.handle'],
  funs: budget
},
{
  routerNames: ['base.orginfo'],
  funs: orgInfo
},
{
  routerNames: ['budget.purchase_sp'],
  funs: purchaseSp
},
{
  routerNames: ['budget.purchase_detail'],
  funs: purchaseDetail
},
{
  routerNames: ['purchase.contract'],
  funs: purchaseContract
},
{
  routerNames: ['purchase.implement'],
  funs: purchaseImplement
},
{
  routerNames: ['budget.purchase'],
  funs: purchase
},
{
  routerNames: ['assets.manage', 'assets.manage_gy', 'assets.manage_dw', '/pages/assets/index'],
  funs: assetsManage
},
{
  routerNames: ['assets.check', '/pages/pandian/index'],
  funs: assetsCheck
},
{
  routerNames: ['assets.in', '/pages/inventory/index'],
  funs: assetsIn
},
{
  routerNames: ['repair.manage', 'assets.repair', '/pages/repair/index'],
  funs: assetsRepair
},
{
  routerNames: ['repair.apply'],
  funs: assetsRepairApply
},
{
  routerNames: ['repair.apply_record'],
  funs: assetsRepairApplyRecord
},
{
  routerNames: ['scrap.apply'],
  funs: assetsScrapApply
},
{
  routerNames: ['scrap.record'],
  funs: assetsScrapApplyRecord
},
{
  routerNames: ['basis.assets_type'],
  funs: assetsType
},
{
  routerNames: ['budget.budget'],
  funs: budget
},
{
  routerNames: ['basis.goods'],
  funs: goods
},
{
  routerNames: ['authority.user'],
  funs: user
},
{
  routerNames: ['authority.operation_log'],
  funs: operationLog
},
{
  routerNames: ['authority.role'],
  funs: role
},
{
  routerNames: ['authority.menu'],
  funs: menu
},
{
  routerNames: ['authority.fun'],
  funs: fun
},
{
  routerNames: ['base.bill', '/pages/invoice/index'],
  funs: bill
},
{
  routerNames: ['base.org_type'],
  funs: orgType
},
{
  routerNames: ['base.org'],
  funs: org
},
{
  routerNames: ['worker.worker'],
  funs: worker
}]
