export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/assets',
      method: 'GET'
    }]
  },
  label: {
    funKey: 'label',
    name: '资产标签',
    apis: [{
      path: '/internalcontrol/assets',
      method: 'GET'
    }]
  },
  ledger: {
    funKey: 'ledger',
    name: '台账',
    apis: [{
      path: '/internalcontrol/assets_scrap_apply',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets/{id}',
      method: 'GET'
    },
    {
      path: '/internalcontrol/budget/detail',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_batch/detail',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_method_batch/detail',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_contract',
      method: 'GET'
    },
    {
      path: '/internalcontrol/acceptance',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets',
      method: 'GET'
    }]
  },
  add_all: {
    funKey: 'add_all',
    name: '新增',
    apis: [{
      path: '/internalcontrol/assets',
      method: 'POST'
    },
    {
      path: '/internalcontrol/worker',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    },
    {
      path: '/internalcontrol/goods',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase',
      method: 'GET'
    }]
  },
  batch_add_all: {
    funKey: 'batch_add_all',
    name: '批量新增',
    apis: [{
      path: '/internalcontrol/assets',
      method: 'POST'
    },
    {
      path: '/internalcontrol/worker',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    },
    {
      path: '/internalcontrol/goods',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_contract',
      method: 'GET'
    }]
  },
  edit_all: {
    funKey: 'edit_all',
    name: '维护',
    apis: [{
      path: '/internalcontrol/assets/{id}',
      method: 'PUT'
    },
    {
      path: '/internalcontrol/worker',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    },
    {
      path: '/internalcontrol/goods',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_contract',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '明细',
    apis: [{
      path: '/internalcontrol/worker',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    },
    {
      path: '/internalcontrol/goods',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_contract',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/internalcontrol/assets/{id}',
      method: 'DELETE'
    }]
  }
}