export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/ucenter/user',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/ucenter/user',
      method: 'POST'
    },
    {
      path: '/ucenter/user/{userId}/authorities',
      method: 'POST'
    },
    {
      path: '/internalcontrol/worker',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    },
    {
      path: '/ucenter/system/roles',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/tree',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/ucenter/user/{userId}',
      method: 'PUT'
    },
    {
      path: '/ucenter/user/{userId}/authorities',
      method: 'POST'
    },
    {
      path: '/internalcontrol/worker',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/all',
      method: 'GET'
    },
    {
      path: '/ucenter/system/roles',
      method: 'GET'
    },
    {
      path: '/internalcontrol/org/tree',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/ucenter/user/{userId}',
      method: 'DELETE'
    }]
  },
  reset_pwd: {
    funKey: 'reset_pwd',
    name: '重置密码',
    apis: [{
      path: '/ucenter/user/{userId}/password/default',
      method: 'PUT'
    }]
  }
}