export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/purchase',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '明细'
  },
  start: {
    funKey: 'start',
    name: '开始审核',
    apis: [{
      path: '/internalcontrol/purchase/start/{id}',
      method: 'POST'
    }]
  },
  no: {
    funKey: 'no',
    name: '驳回',
    apis: [{
      path: '/internalcontrol/purchase/check/{id}',
      method: 'POST'
    }]
  },
  pass: {
    funKey: 'pass',
    name: '通过',
    apis: [{
      path: '/internalcontrol/purchase/check/{id}',
      method: 'POST'
    }]
  },
  back: {
    funKey: 'back',
    name: '撤销',
    apis: [{
      path: '/internalcontrol/purchase/back/{id}',
      method: 'POST'
    }]
  }
}