export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/purchase',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '明细',
    apis: [{
      path: '/internalcontrol/purchase/detail',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase/type/{id}',
      method: 'POST'
    },
    {
      path: '/internalcontrol/purchase/detail/del/type/{id}',
      method: 'POST'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/internalcontrol/purchase',
      method: 'POST'
    },
    {
      path: '/internalcontrol/purchase/apply',
      method: 'GET'
    },
    {
      path: '/internalcontrol/worker',
      method: 'GET'
    }]
  },
  edit: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/internalcontrol/purchase/{id}',
      method: 'PUT'
    },
    {
      path: '/internalcontrol/purchase/apply',
      method: 'GET'
    },
    {
      path: '/internalcontrol/worker',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/internalcontrol/purchase/{id}',
      method: 'DELETE'
    }]
  },
  push: {
    funKey: 'push',
    name: '提交',
    apis: [{
      path: '/internalcontrol/purchase/submit/{id}',
      method: 'POST'
    }]
  },
  back: {
    funKey: 'back',
    name: '撤回',
    apis: [{
      path: '/internalcontrol/purchase/recall/{id}',
      method: 'POST'
    }]
  }
}